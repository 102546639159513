import React from "react";
import classNames from "./contactnav.module.scss";

//assets
import homeIcon from "../../assets/images/icons/home.svg";
import callIcon from "../../assets/images/icons/call.svg";
import emailIcon from "../../assets/images/icons/email.svg";
import whatsappIcon from "../../assets/images/icons/whatsapp.svg";

const ContactNav = () => {
  //constant
  const allContacts = [
    { title: "Visit Us", desc: "Banjara Hills", image: homeIcon },
    { title: "Call Us", desc: "+91 559 018341", image: callIcon },
    { title: "Email Us", desc: "shorupan@gmail.com", image: emailIcon },
    { title: "WhatsApp", desc: "Lets Talk Now", image: whatsappIcon },
  ];

  return (
    <div className={classNames.contactNav}>
      <div className={classNames.wrapper}>
        {allContacts.map((eachContact, index) => {
          return (
            <div className={classNames.eachContact}>
              <img src={eachContact.image} alt={eachContact.title} />
              <div className={classNames.content}>
                <div className={classNames.title}>{eachContact.title}</div>
                <div className={classNames.desc}>{eachContact.desc}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContactNav;
