import React, { useState } from "react";
import classNames from "./navbar.module.scss";

//assets
import { TiArrowSortedDown } from "react-icons/ti";
import fullLogo from "../../assets/images/app/logo.svg";

const Navbar = () => {
  const [selectedNav, setSelectedNav] = useState("Platforms");

  //constant
  const navOptions = [
    { title: "Platforms", subMenu: true },
    { title: "Who We Serve", subMenu: true },
    { title: "About Us", subMenu: true },
    { title: "Pricing", subMenu: true },
  ];

  return (
    <div className={classNames.navbar}>
      <div className={classNames.wrapper}>
        <img src={fullLogo} alt="fullLogo" />
        <div className={classNames.navOptions}>
          <div className={classNames.navigations}>
            {navOptions?.map((eachNav, index) => {
              return (
                <div
                  key={eachNav?.title + index}
                  className={`${classNames.eachNav} ${
                    selectedNav === eachNav?.title && classNames.selectedNav
                  }`}
                  onClick={() => setSelectedNav(eachNav?.title)}
                >
                  {eachNav?.title}
                  <TiArrowSortedDown />
                </div>
              );
            })}
          </div>
          <div className={classNames.submitBtn}>Request A Demo</div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
