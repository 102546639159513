import React from "react";
import classNames from "./landing.module.scss";
import Navbar from "../../components/Navbar";
import ContactNav from "../../components/Contactnav";
import MainContainer from "../../components/Landing/Main";

const Landing = () => {
  return (
    <div className={classNames.landing}>
      <Navbar />
      <ContactNav />
      <MainContainer />
    </div>
  );
};

export default Landing;
