import React from "react";
import classNames from "./maincontainer.module.scss";

//assets
import hallImage from "../../../assets/images/pages/landing/hall.png";

const MainContainer = () => {
  return (
    <div className={classNames.main}>
      <div className={classNames.wrapper}>
        <div className={classNames.content}>
          <div className={classNames.title}>
            Powering The Next Gen Of <span>Health</span> Care Providers
          </div>
          <div className={classNames.desc}>
            Automate your practice and empower your team with our cloud-based
            suite.
          </div>
        </div>
        <img src={hallImage} alt="hallImage" />
      </div>
    </div>
  );
};

export default MainContainer;
